import * as React from "react"
import PropTypes from "prop-types"

const SortBy = ({changeCallback, sortby}) => {
  
  return (
	 	<div className="order-by">
			 <label>Order by</label>
			 <select name="order-by" onChange={(e) => { changeCallback(e); }} value={sortby}>
				 <option key="0" value="pinned">Pinned</option>
				 <option key="1" value="impact_desc">Impact (High first)</option>
				 <option key="2" value="impact_asc">Impact (Low first)</option>
				 <option key="3" value="age_desc">Age (Newest first)</option>
				 <option key="4" value="age_asc">Age (Oldest first)</option>
			 </select>
	 	</div>
  )
}

SortBy.propTypes = {
	  changeCallback: PropTypes.func.isRequired,
	  sortby: PropTypes.string.isRequired
}

export default SortBy