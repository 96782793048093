import React, {useState, useEffect} from "react"
import CountryFilter from "./country_filter"
import SortBy from "./sort_by"
import Incident from "./incident"
import PropTypes from "prop-types"
import { isAdmin } from "../../services/auth"
import {navigate} from "gatsby"
import { useLocation } from '@reach/router';

import "./incidents_bar.scss"
import Loading from "../common/loading"

const Incidents = ({close, closeCallback, showCountry, incidents, loading, filterController}) => {
	const [country, setCountry] = useState("")
	const [orderBy, setOrderBy] = useState("age_desc")

	const location = useLocation();

	useEffect(()=>{
		filterController.setCountry(country)
	}, [country])

	if(typeof(showCountry) == 'undefined') {
		showCountry = true
	}

	const addCallback = function(e) {
		e.preventDefault()

		navigate('/edit/')
	}

	let incidentsList = [];
	if(loading){
		incidentsList = (<Loading />)
	}else if(incidents && incidents.length > 0) {
		incidentsList = []
		for(const key in incidents) {
			const inc = incidents[key]

			incidentsList.push(
				<Incident 
				id={inc.ID}
				key={key}
				city={inc.City}
				type={inc.IncidentType}
				country={inc.Country}
				region={inc.Region} 
				current={inc.Status === "current"}
				impact={inc.Impact} 
				start_date={inc.StartDate.substring(0,10)}
				reporting_date={inc.ReportingDate.substring(0, 10)}
				zipcodes={inc.Zipcodes} 
				description={inc.Description}
				sourceName={inc.Source}
				sourceUrl={inc.DataSourceUrl} 
				pinned={inc.Pinned == 1}
				delay={inc.ExpectedDelayText} />)
		}
	} else if(incidents != null && incidents.length == 0) {
		incidentsList = (<div className="no-incidents">No incidents found</div>)
	}

	let closeButton = []

	if(close) {
		closeButton = (<a href="#close" className="close-button" onClick={closeCallback}>x</a>)
	}

	let additionalFilters = []
	if(showCountry) {
		additionalFilters.push(<div key={1} className="col-sm-12 col-md-6">
				<CountryFilter changeCallback={(e) => {setCountry(e.target.value)}} country={country} />
			</div>)
	}
	additionalFilters.push(<div key={2} className="col-sm-12 col-md-6">
			<SortBy changeCallback={(e) => {
				setOrderBy(e.target.value); 
				filterController.orderBy(e.target.value);
				}} sortby={orderBy} />
		</div>)

	let addButton = (<></>)
	
	if(isAdmin()) {
		if(location.pathname.startsWith("/incident-map") || location.pathname.startsWith("/network-disruption-database")){
			addButton = (<a href="#add" className="add-button close-button" onClick={addCallback}>+</a>)
		}
	}
  return (
	 	<div className="incident-list">
			<h4>Alerts ({incidents.length})</h4>

			{closeButton}

			{addButton}

			<div className="row filters">
				{additionalFilters}	
			</div>

			{incidentsList}

	 	</div>
  )
}

Incidents.propTypes = {
	close: PropTypes.bool,
	closeCallback: PropTypes.func,
	preLoadedIncidents: PropTypes.array,
	overrideFilters: PropTypes.object,
	changeOrderCallback: PropTypes.func,
	showCountry: PropTypes.bool,

}
export default Incidents