import React, {useState, useEffect, useRef} from "react"

import "./holiday_banner.scss"
import {getHolidays} from "../../../services/api"
import HolidayPopup from "./holiday_popup"

const HolidayBanner = () =>{
  const [scrolling, setScrolling] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const holidayScroll = useRef();
  const [width, setWidth] = useState(typeof(window) === 'undefined' ? 0 : window.innerWidth);
  const [animation, setAnimation] = useState(null);
  const [selectedHoliday, setSelectedHoliday] = useState(null);

  useEffect(() =>{
    getHolidays().then((holidays) => {
      setHolidays(holidays)
    })
  }, []) 


  //update after setHolidays
  useEffect(() => {
    if(!holidays){
      return;
    }
    if(holidayScroll.current.offsetWidth > width - 180){
      setScrolling(true);
      setAnimation( holidayScroll.current.animate([
        { 
          transform : 'translateX(100px)',
        },
        {
          transform : 'translateX(calc(-100% + '+(width/2)+'px))',
        }
      ],
      {
        duration: holidays.length*6000,//6 seconds per element
        iterations: Infinity
      }))
    }else{
      if (animation !== null){
        animation.pause()
        setScrolling(false)
        holidayScroll.current.style.transform = '';
      }
    }

  }, [holidays])



  
  const stopScrolling = () => {
    if (animation !== null){
      animation.pause()
    }
  }

  const startScrolling = () => {
    if (animation !== null && scrolling){
      animation.play()
    }
  }

  
  const openHolidayPopup = (holiday) => {
    setSelectedHoliday(holiday)
  }

  const showCountryCodes = (countries) =>{
    let copy_countries = countries.slice(0,5);

    let text = copy_countries.map((country, index) => {
      return (<span className="country-code" key={index}>{country.iso}</span>)
    })

    if(countries.length > 5){
      return <>{text} + Others</>
    }
    return <>{text}</>
  }
  
  const showHolidays = () =>{
    return (
      <div className="holidays-wrapper">
        <div ref={holidayScroll} className="holidays" onMouseEnter={(e) => {stopScrolling()}} onMouseOut={(e) => {startScrolling()}}>
          {holidays.map((holiday, index)=>{
          return (
            <div onClick={() => openHolidayPopup(holiday)} className="holiday-alert" key={index} onMouseEnter={(e) => {stopScrolling()}} onMouseOut={(e) => {startScrolling()}}>
              <span className="holiday-alert-text">
                {holiday.name} in {holiday.daysFromNow} days impact on 
                </span>
              <span className="holiday-alert-countries"> {holiday.countries.length} countries: {showCountryCodes(holiday.countries)}</span>
            </div>
          )
        })}
        </div>
    </div>)
  }


  return (
    <>
      <div className="holiday-banner">
        <span className="public-holidays">Public holidays</span>
          {showHolidays()}
      </div>
      <HolidayPopup holiday={selectedHoliday}/>
    </>
  )
}


export default HolidayBanner