import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"

import "./holiday_popup.scss"

import ImpactBar from "../../incidents/impact_bar"

const HolidayPopup = ({holiday}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if(holiday){
      setIsOpen(true);
    }else{
      setIsOpen(false);
    }
  }, [holiday])
  
  const closePopup = () => {
    setIsOpen(false);
  }

  const showCountries = () => {
    return holiday.countries.map((country, index) => {
      return (
      <div className="country" key={index}>
        {country.name}
      </div>)
    })
  }

  const delay = () =>{
    let end_date = new Date(holiday.end_date);
    end_date.setDate(end_date.getDate() +1);

    return Math.abs((new Date(holiday.start_date)).getDate() - end_date.getDate())
  }

  const start_date = () =>{
    let staret_date = new Date(holiday.start_date);

    return staret_date.getDate()+"-"+(staret_date.getMonth()+1)+"-"+staret_date.getFullYear()
  }

  const windowContents = () => {
    if(!holiday){
      return;
    }
    return (
    <div className="holiday-window">
      <div className="close-button" onClick={() => closePopup()}></div>
      <div className="contents">
        <h4 className="name">{holiday.name}</h4>
        <ImpactBar impact="medium"/>
        <div className="date-wrapper">
          <div className="start-date-wrapper">
            <div className="text">{start_date()}</div>
            <div className="title">Start date</div>
          </div>
          <div className="delay-wrapper">
            <div className="text">{delay()}</div>
            <div className="title">Delay</div>
          </div>
        </div>
        <div className="countries">
          Countries: {showCountries()}
        </div>
      </div>
    </div>
    )
  }

  return (
    <div className={"holiday-popup"+ (isOpen ? " open" : "")}>
      {windowContents()}
    </div>
  )
}

HolidayPopup.propTypes = {
  holiday: PropTypes.object
}


export default HolidayPopup